<template>
   <div class="profile">
      <router-view />

      <!-- 		<AdviceHeader :title="this.$t('Profile.title')" /> -->
      <!--       <div class="profile__menu" v-if="false"> its unnedded 
         <AdviceMenu />
      </div> -->
      <!-- 		<div class="profile__box" v-if="profile && !isLoading">
         <div class="profile__img">
            <ProfileImage :imgLink="profileImage" />
         </div>
         <div class="profile__input">
            <div class="profile__item" style="margin-top: 50px">
               <div class="env-settings">
                  <ProfileTheme />
                  <ProfileFontSize />
               </div>
            </div>
            <router-link :to="{ name: 'app-instructions' }" class="instructions">
               <svg>
                  <use xlink:href="../../assets/img/icons.svg#instructions"></use>
               </svg>
               <AdviceText>{{ $t('Profile.instructions') }}</AdviceText>
            </router-link>
            <div class="profile__item">
               <ProfileSubscription />
            </div>
            <button @click="callInstallPWA" class="instructions">
               <svg>
                  <use xlink:href="../../assets/img/icons.svg#download-pwa"></use>
               </svg>
               <AdviceText>{{ $t('Main.appInstall') }}</AdviceText>
            </button>
            <div class="profile__item">
               <ProfileTextInput
                  :itemImg="'profile-name-icon'"
                  :currentValue="profile.first_name"
                  :labelText="$t('Profile.nameLabel')"
                  :labelEditText="$t('Profile.nameEditLabel')"
                  :variableName="'first_name'"
               />
            </div>
            <div class="profile__item">
               <ProfileTextInput
                  :itemImg="'profile-name-icon'"
                  :currentValue="profile.last_name"
                  :labelText="$t('Profile.lastNameLabel')"
                  :labelEditText="$t('Profile.lastNameEditLabel')"
                  :variableName="'last_name'"
               />
            </div>
                       <div class="profile__item">
               <ProfileTextInput
                  :itemImg="'profile-phone-icon'"
                  :currentValue="profile.phone"
                  :labelText="$t('Profile.phoneLabel')"
                  :labelEditText="$t('Profile.phoneEditLabel')"
                  :variableName="'phone'"
               />
            </div> 
                        <div class="profile__item">
               <ProfileTextInput
                  :itemImg="'profile-email-icon'"
                  :currentValue="profile.email"
                  :labelText="$t('Profile.emailLabel')"
                  :labelEditText="$t('Profile.emailEditLabel')"
                  :variableName="'email'"
               />
            </div>
                       <div class="profile__item">
               <ProfileOptionInput
                  :itemImg="'profile-locale-icon'"
                  :currentValue="profile.locale"
                  :labelText="$t('Profile.localeLabel')"
                  :labelEditText="$t('Profile.localeEditLabel')"
                  :variableName="'locale'"
                  :options="['en']"
               />
            </div> 
            <div class="profile__item" v-if="profile.email">
               <ProfileItem
                  :itemImg="'profile-email-icon'"
                  :currentValue="profile.email"
                  :labelText="$t('Profile.emailLabel')"
                  :labelEditText="$t('Profile.emailEditLabel')"
                  :variableName="'email'"
                  :disabled="true"
               />
            </div>
            <div class="profile__item" v-if="profile.phone">
               <ProfileItem
                  :itemImg="'profile-phone-icon'"
                  :currentValue="profile.phone"
                  :labelText="$t('Profile.phoneLabel')"
                  :labelEditText="$t('Profile.phoneEditLabel')"
                  :variableName="'phone'"
                  :disabled="true"
               />
            </div>
            <div class="profile__item">
               <ProfileOptionInput
                  :itemImg="'profile-gender-icon'"
                  :currentValue="profile.gender"
                  :labelText="$t('Profile.genderLabel')"
                  :labelEditText="$t('Profile.genderEditLabel')"
                  :variableName="'gender'"
                  :options="['male', 'female', 'uncertain']"
               />
            </div>

            <div class="profile__item">
               <ProfileDateInput
                  :itemImg="'profile-birthday-icon'"
                  :currentValue="profile.birthday"
                  :labelText="$t('Profile.birthdayLabel')"
                  :labelEditText="$t('Profile.birthdayEditLabel')"
                  :variableName="'birthday'"
               />
            </div>
            <div v-if="!isTrialSubscription">
               <SocialButton
                  :isAdded="false"
                  @click="() => (isConfirmingCancelSubscription = true)"
                  class="cancel-subscription"
               >
                  <AdviceText>{{ $t('Subscription.cancel') }}</AdviceText>
               </SocialButton>
            </div>
                      <div class="instructions messagers">
               <svg>
                  <use xlink:href="../../assets/img/icons.svg#instructions"></use>
               </svg>
            </div>
            <ProfileMessangers />
            <div class="profile__item change-password">
               <ProfileChangeModal :text="profile.email ? $t('Profile.changeEmail') : $t('Profile.addEmail')">
                  <template v-slot:default="{ close }">
                     <ProfileChangeEmailForm v-if="profile.email" @closeModal="close" />
                     <ProfileAddEmailForm v-else @closeModal="close" />
                  </template>
               </ProfileChangeModal>
            </div>
            <div class="profile__item change-password">
               <ProfileChangeModal :text="profile.phone ? $t('Profile.changePhone') : $t('Profile.addPhone')">
                  <template v-slot:default="{ close }">
                     <ProfileChangePhoneForm v-if="profile.phone" @closeModal="close" />
                     <ProfileAddPhoneForm v-else @closeModal="close" />
                  </template>
               </ProfileChangeModal>
            </div>
            <div class="profile__item change-password">
               <ProfileChangeModal :text="$t('Profile.changePassword')">
                  <template v-slot:default="{ close }">
                     <ProfileChangePasswordForm @closeModal="close" />
                  </template>
               </ProfileChangeModal>
            </div>
         </div>

      </div>
      <Spinner v-else />
      <AdviceModal @closeModal="cancelConfirm" v-if="isConfirmingCancelSubscription">
         <div class="box">
            <AdviceText :bigger="true" class="title">{{ $t('Subscription.cancelText') }}</AdviceText>
            <div class="btns">
               <AdviceBtn @click.native="cancelConfirm">
                  <AdviceText :smaller="true">{{ $t('Subscription.btnCancel') }}</AdviceText>
               </AdviceBtn>
               <AdviceBtn @click.native="cancelSubscription">
                  <AdviceText :smaller="true"> {{ $t('Subscription.btnConfirm') }} </AdviceText>
               </AdviceBtn>
            </div>
         </div>
      </AdviceModal> -->
   </div>
</template>

<script>
//import log from '../../logger/logger';

export default {
   mounted(){
  //    log(this.$http, { message: 'visited page /settings', level: 'info' });
   }
}

/* import ProfileMessangers from '../../components/ProfileComponents/ProfileMessangers.vue';
import AdviceHeader from '../../components/AdviceComponents/AdviceHeader.vue';
import ProfileImage from '../../components/ProfileComponents/ProfileImage.vue';
import ProfileTextInput from '../../components/ProfileComponents/ProfileTextInput.vue';
import ProfileOptionInput from '../../components/ProfileComponents/ProfileOptionInput.vue';
import ProfileDateInput from '../../components/ProfileComponents/ProfileDateInput.vue';
import ProfileSubscription from '../../components/ProfileComponents/ProfileSubscription.vue';
import ProfileTheme from '../../components/ProfileComponents/ProfileTheme.vue';
import ProfileChangePasswordForm from '../../components/ProfileComponents/ProfileChangePasswordForm.vue';
import ProfileChangeEmailForm from '../../components/ProfileComponents/ProfileChangeEmailForm.vue';
import ProfileChangePhoneForm from '../../components/ProfileComponents/ProfileChangePhoneForm.vue';
import ProfileChangeModal from '../../components/ProfileComponents/ProfileChangeModal.vue';
import ProfileFontSize from '../../components/ProfileComponents/ProfileFontSize.vue';
import Spinner from '../../components/UI/Spinner.vue';
import mutations from '../../store/mutation-types';
import remoteOrigin from '../../store/remoteOrigin';
import actions from '../../store/action-types';
import ProfileItem from '../../components/ProfileComponents/ProfileItem.vue';
import AdviceText from '../../components/AdviceComponents/AdviceText.vue';
import ProfileAddPhoneForm from '../../components/ProfileComponents/ProfileAddPhoneForm.vue';
import ProfileAddEmailForm from '../../components/ProfileComponents/ProfileAddEmailForm.vue';
import SocialButton from '../../components/ContactsConponents/SocialButton.vue';
import AdviceModal from '../../components/AdviceComponents/AdviceModal.vue';
import AdviceBtn from '../../components/AdviceComponents/AdviceBtn.vue'; */

/* export default {
      components: {
      AdviceHeader,
      ProfileImage,
      ProfileTextInput,
      ProfileOptionInput,
      Spinner,
      ProfileSubscription,
      ProfileMessangers,
      ProfileTheme,
      ProfileDateInput,
      ProfileChangePasswordForm,
      ProfileChangeEmailForm,
      ProfileChangeModal,
      ProfileFontSize,
      ProfileChangePhoneForm,
      ProfileItem,
      AdviceText,
      ProfileAddPhoneForm,
      ProfileAddEmailForm,
      SocialButton,
      AdviceModal,
      AdviceBtn,
   },
 
   provide() {
      return {
         updateFontSize: this.updateFontSize,
      };
   },

   inject: ['getCurrentFontSize', 'installPWA'],

   data() {
      return {
         isDataLoading: false,
         theme: '',
         currentTheme: '',
         isConfirmingCancelSubscription: false,
      };
   },

   computed: {
      isTrialSubscription() {
         return this?.profile?.subscribtion === 'trial';
      },

      profile() {
         return this.$store.getters['profile/getProfile'];
      },

      profileImage() {
         let url;
         if (this?.profile?.img) {
            url = `${remoteOrigin}${this.profile.img}`;
         } else if (
            localStorage.social_img &&
            localStorage.social_img !== 'undefined' &&
            localStorage.social_img !== 'null'
         ) {
            url = localStorage.social_img;
         } else {
            url = null;
         }
         return url;
      },

      isLoading() {
         return this.$store.getters['advice/getLoading'];
      },

      isProfileLoaded() {
         return this.$store.getters['profile/getProfileLoaded'];
      },
   },

   created() {
      this.$store.commit(`advice/${mutations.UPDATE_TITLE}`, 'Profile');
      this.isDataLoading = false;
      this.updateFontSize();
      if (!this.isProfileLoaded) {
         this.$store.dispatch(`profile/${actions.FETCH}`);
      }
   },

   methods: {
      updateFontSize() {
         this.font = localStorage.fontSize ? localStorage.fontSize : 'medium';
      },

      setFontSizeToLocalStorage(value) {
         localStorage.fontSize = value;
         this.$store.commit(`advice/${mutations.UPDATE_FONT_SIZE}`, this.getCurrentFontSize());
      },

      callInstallPWA() {
         sessionStorage.removeItem('doNotShowInstall');
         this.installPWA();
      },

      async cancelSubscription() {
         try {
            await this.$http.put('resources/profile/canceled', { canceled: true });
            alert('Subscription was canceled');
            this.isConfirmingCancelSubscription = false;
            this.$store.dispatch(`profile/${actions.FETCH}`);
         } catch (e) {
            console.log(e); //TODO: handle error
         }
      },

      cancelConfirm() {
         this.isConfirmingCancelSubscription = false;
      },
   },
}; */


</script>

<style scoped>
.profile {
   display: flex;

   max-width: 876px;
   background-color: var(--surface);
   color: var(--font-color-black);
   margin: 0 auto;
   padding-top: 40px;
}
.content__menu {
   display: flex;
   width: 100%;
   flex-direction: column;
   font-size: 24px;
   font-weight: 700;
   line-height: 31px;
}
.menu__linck_account,
.menu__linck_installApp,
.menu__pricing {
   border-bottom: 0.5px solid black;
}
.menu__linck_account,
.menu__linck_installApp,
.menu__pricing,
.menu__logOut {
   padding: 32px 5px;
   position: relative;
   width: 100%;
}
.menu__linck_installApp,
.menu__logOut {
   display: flex;
}

.img {
   position: absolute;
   right: 16px;
   bottom: 33px;
}
@media screen and (max-width: 450px) {
   .profile {
      padding-top: 0px;
   }
}
/* .profile
   padding-top: 50px

   &__box
      margin: 10px auto 0
      padding: 0 20px 20px
      max-width: 1300px
      width: 100%

   &__img
      margin-bottom: 10px

   &__discount
      margin: 20px
      font-size: 1.1rem
      font-weight: 400

      & span
         font-weight: 300
         font-size: 1rem

   & .env-settings
      margin-left: 40px
      display: flex
      border-bottom: 1px solid #f1f1f1
      padding-bottom: 10px
      align-items: center
      flex-wrap: wrap

      & > *:first-child
         margin-left: -40px
         margin-right: 30px

@media (max-width:600px)
   .profile .env-settings
      justify-content: space-between

      & > *:first-child
         margin-left: -40px
         margin-right: 10px

@media (max-width:364px)
   .profile .env-settings
      display: block

      & > *:first-child, & > *:last-child
         margin-left: -40px
         margin-right: 0
      & > *:last-child
         margin-top: 20px

#sepia .profile .env-settings
   border-bottom: 1px solid $app-sepia-border

#dark .profile .env-settings
   border-bottom: 1px solid #f1f1f1

.change-password
   text-align: right
   margin-top: 20px

.instructions
   width: 100%
   display: flex
   align-items: center
   margin-left: auto
   margin-right: auto
   padding: 10px 0
   cursor: pointer
   transform: scale(1)
   transition: all 0.3s
   color: inherit
   text-decoration: none

   & > p
      padding: 10px 0
      width: 100%
      border-bottom: 1px solid #f1f1f1

   & > *
      cursor: pointer

   & svg
      fill: #76c4ba
      stroke: #76c4ba
      width: 25px
      height: 25px
      margin-right: 20px

button.instructions
   text-align: left

.instructions.messagers > *
   cursor: default

.instructions.messagers:hover:not(.instructions.messagers:active)
   cursor: default
   transform: none

.cancel-subscription
   margin-top: 20px

   & *
      cursor: pointer

@media (max-width: 768px)
   .cancel-subscription
      margin: 20px auto 0

#sepia .profile .instructions p
   border-bottom: 1px solid $app-sepia-border

#dark .profile .instructions p
   border-bottom: 1px solid #f1f1f1

.box
   max-width: 380px
   width: 100%
   margin: 0 auto

.title
   text-align: center

.btns
   margin-top: 40px
   display: flex
   justify-content: space-between */
</style>
